<template>
  <div class="frais-interne-ihs">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-1">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-1">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-1">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px "
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterStatut"
            :options="[
              { full_name: 'Tous', valus: null },
              ...computedValidationList
            ]"
            text-field="full_name"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <div
          v-if="getLoadingFraisBddInterneIhs"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <!-- <global-validation-cactus
            v-if="cantCreate($options.name) && !isSuperAdminGetter"
            :month="filterMonth"
            :year="filteryear"
            :type="'IHS'"
             :ihs_type="item.ihs_type"
            @reloadData="reloadData"
            :dataToUse="computedFraisIhs"
          /> -->
          <extraction-cactus
            v-if="cantExport(this.$options.name)"
            :filterType="'IHS'"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :dataToUse="computedFraisIhs"
            :selected="computedCheckedRows.length"
            :filter="ComputedFilter"
          />
          <generation-facture-avoir-frais
            :interfaceName="this.$options.name"
            :filterType="'IHS'"
            :dataToUse="computedFraisIhs"
            @reloadData="reloadData"
            curentInterface="cactus"
          />
          <validation-statue-installateur
            v-if="
              cantUpdate(this.$options.name) &&
                filterType &&
                computedCheckedRows.length
            "
            :filterType="'IHS'"
            :dataToUse="computedFraisIhs"
            @reloadData="reloadData"
            curentInterface="cactus"
          />
          <!--  
      <div v-if="getLoadingMail" class="chargement chargement-loading-icon">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-if="
              !getLoadingMail &&
                computedCheckedRows &&
                computedCheckedRows.length > 0
            "
          >
            <sendMailCommercialCactus
              :type="filterType"
              :dataToUse="computedFraisIhs"
              v-if="!isSuperAdminGetter && cantSend($options.name)"
            />
          </div> -->
          <!-- <extraction-cactus-invalid
            v-if="cantExport(this.$options.name) && filterType"
            :filterType="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
              :dataToUse="computedFraisIhs"

          /> -->
          <!-- <b-button
            v-if="cantRefresh(this.$options.name) && this.filterMonth != null"
            size="sm"
            class="button-export-style  ml-2"
            @click="refreshBddInterneIhs"
            ><img src="@/assets/arrows_rotate.png"
          /></b-button> -->
        </div>
      </div>
      <div class="body-box-rapport ">
        <div class="table-rapport-style w-100-p">
          <b-table
            show-empty
            id="my-table"
            class="custom-table-style customTableFraisInterneIhs table-header"
            :items="computedFraisIhs"
            :fields="computedFields"
            bordered
            sticky-header
            hover
            responsive
            head-variant="light"
            :tbody-tr-class="rowClass"
            :sort-by.sync="sortBy"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:head()="data">
              <div class="text-uppercase">
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="data.field.details == false"
                  @click="setVisibilityFieldsHeader(data.field.key)"
                />
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="data.field.details == true"
                  @click="setVisibilityFieldsHeader(data.field.key)"
                />

                <span
                  v-if="data.field.key != 'check_all'"
                  :class="{
                    'white-color': computedHeaderVisibility(data.field.key)
                  }"
                >
                  {{ data.field.label }}</span
                >
                <div v-else>
                  <b-form-checkbox
                    v-model="checkAll"
                    @change="checkAllFunction"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                @change="checkAllVerif(data.item)"
                class="check-th"
                :value="true"
                :unchecked-value="false"
                v-if="data.item.projects.length != 0"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(details)="data">
              <button class="btn btn-anomalie" @click="getDataForRow(data)">
                <font-awesome-icon
                  class="icon-plus-h"
                  icon="plus-square"
                  v-if="data.detailsShowing == false"
                  title="Afficher les dossiers"
                />
                <font-awesome-icon
                  class="icon-plus-h"
                  icon="minus-square"
                  v-if="data.detailsShowing == true"
                  title="Masques les dossiers"
                />
              </button>
            </template>
            <template v-slot:cell(numero_dossier)="">
              -
            </template>
            <template v-slot:cell(installeur)="data">
              <span
                @click="handleDownloadExtraitInstalleur(data.item.name)"
                :title="'Télécharger extrait ' + data.item.name"
              >
                {{ data.item.name }}
              </span>
            </template>
            <template v-slot:cell(regie)=""> - </template>
            <!-- <template v-slot:cell(previsiteur)=""> - </template> -->
            <!-- <template v-slot:cell(acompte_exisit)=""> - </template> -->
            <template v-slot:cell(montant_final)=""> - </template>
            <template v-slot:cell(agent_commercial_terrain)=""> - </template>
            <template v-slot:cell(agent_commercial)=""> - </template>
            <template v-slot:cell(montant_commercial)="data">
              <span
                @click="
                  handleDownloadExtraitSupportService({
                    item: data.item,
                    type: 'commercial_commercial'
                  })
                "
              >
                {{ data.item.commercial }}
              </span>
            </template>
            <template v-slot:cell(commercial_statue)=""> - </template>
            <template v-slot:cell(commercial_date)=""> - </template>
            <template v-slot:cell(commercial_numer)=""> - </template>
            <template v-slot:cell(commercial_numero)=""> - </template>
            <template v-slot:cell(commercial_statue_installateur)="">
              -
            </template>
            <template v-slot:cell(montant_interne)="data">
              <span
                @click="
                  handleDownloadExtraitSupportService({
                    item: data.item,
                    type: 'commercial_interne'
                  })
                "
              >
                {{ data.item.interne }}
              </span>
            </template>
            <template v-slot:cell(interne_statue)=""> - </template>
            <template v-slot:cell(interne_date)=""> - </template>
            <template v-slot:cell(interne_numer)=""> - </template>
            <template v-slot:cell(interne_numero)=""> - </template>
            <template v-slot:cell(interne_statue_installateur)="">
              -
            </template>
            <template v-slot:cell(montant_ihs)="data">
              <span
                @click="
                  handleDownloadExtraitSupportService({
                    item: data.item,
                    type: 'commercial_ihs'
                  })
                "
              >
                {{ data.item.ihs }}
              </span>
            </template>
            <template v-slot:cell(ihs_statue)=""> - </template>
            <template v-slot:cell(ihs_date)=""> - </template>
            <template v-slot:cell(ihs_numer)=""> - </template>
            <template v-slot:cell(ihs_numero)=""> - </template>
            <template v-slot:cell(ihs_statue_installateur)="">
              -
            </template>
            <template v-slot:cell(ihs_type)=""> - </template>
            <template #row-details="data">
              <div v-if="data.item.loadingDetailt">
                <circles-to-rhombuses-spinner
                  :animation-duration="1500"
                  :size="50"
                  :color="'#ff1d5e'"
                  class="loading-details-frais"
                />
              </div>
              <span
                v-else
                v-for="(item, index) in data.item.projects"
                :key="data.item.nom + item.nom1 + index"
                class="align-td-item border-botom"
              >
                <td class="color">
                  <div class="block-service-style">
                    <b-form-checkbox
                      v-model="item.check"
                      @change="checkAllVerifDetails(data.item, item)"
                      class="check-th-details ml-2"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </div>
                </td>
                <td class="details-column-frais">
                  <div class="block-service-style"></div>
                </td>
                <td class="installeur-column-frais">
                  {{ item.nom1 + ' ' + item.prenom1 }}
                </td>

                <td class="item-details-style">
                  {{ item.numero_dossier }}
                </td>
                <td class="item-details-style">
                  {{ item.montant_final }}
                </td>
                <td class="item-details-style">
                  <EditableInput
                    :editable="
                      computedEditableStatutIFrais({
                        item: item,
                        column: 'ihs'
                      })
                    "
                    champName="ihs_type"
                    editableType="select"
                    :optionsSelect="[
                      { value: 'IHS HABITAT', full_name: 'IHS HABITAT' },
                      {
                        value: 'RENOV & MON HABITAT',
                        full_name: 'RENOV & MON HABITAT'
                      }
                    ]"
                    :item="item"
                    :value="item.ihs_type"
                    :updateFunction="updateFraisBddInterneIhs"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                  />
                </td>
                <td class="item-details-style">
                  {{ item.regie }}
                </td>
                <!-- <td class="item-details-style">
                  {{ item.previsiteur }}
                </td> -->
                <!-- <td class="item-details-style">
                  <b-form-checkbox
                    :disabled="true"
                    v-model="item.acompte_exisit"
                    name="check-button"
                    switch
                    :value="500"
                    :unchecked-value="0"
                  >
                  </b-form-checkbox>
                </td> -->
                <td class="item-details-style">
                  {{ item.agent_commercial_terrain }}
                </td>
                <td class="item-details-style">
                  {{ item.agent_commercial }}
                </td>
                <!-- ALTEO FRANCE Support Commercial -->
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn('ALTEO FRANCE Support Commercial')
                  "
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue text-center"
                      :class="{
                        'red-color':
                          item.commercial_statue == 'facture non validée',
                        'green-color': item.commercial_statue == 'paye',
                        'blue-color': item.commercial_statue == 'passive',
                        'red-color montant-bare':
                          item.commercial_statue == 'annulee'
                      }"
                    >
                      {{
                        computedFactureVideValue(item, 'commercial', 'montant')
                      }}
                    </span>
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Support Commercial',
                      key: 'montant_commercial'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(
                      item,
                      'commercial',
                      'commercial_statue'
                    ) | statutFactureFormat
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Support Commercial',
                      key: 'montant_commercial'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(
                      item,
                      'commercial',
                      'commercial_date'
                    )
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Support Commercial',
                      key: 'montant_commercial'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(
                      item,
                      'commercial',
                      'commercial_numero'
                    )
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Support Commercial',
                      key: 'montant_commercial'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(
                      item,
                      'commercial',
                      'commercial_statue_installateur'
                    ) | statutFactureFormat
                  }}
                </td>
                <!-- ALTEO FRANCE Régie Commerciale INTERNE -->
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn(
                      'ALTEO FRANCE Régie Commerciale INTERNE'
                    )
                  "
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue text-center"
                      :class="{
                        'red-color':
                          item.interne_statue == 'facture non validée',
                        'green-color': item.interne_statue == 'paye',
                        'blue-color': item.interne_statue == 'passive',
                        'red-color montant-bare':
                          item.interne_statue == 'annulee'
                      }"
                    >
                      {{ computedFactureVideValue(item, 'interne', 'montant') }}
                    </span>
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                      key: 'montant_interne'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(item, 'interne', 'interne_statue')
                      | statutFactureFormat
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                      key: 'montant_interne'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(item, 'interne', 'interne_date')
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                      key: 'montant_interne'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(item, 'interne', 'interne_numero')
                  }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                      key: 'montant_interne'
                    })
                  "
                >
                  {{
                    computedFactureVideValue(
                      item,
                      'interne',
                      'interne_statue_installateur'
                    ) | statutFactureFormat
                  }}
                </td>
                <!-- INTERNE IHS -->
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="computedShowDetailsColomn('INTERNE IHS')"
                >
                  <div class="block-service-style">
                    <span
                      class="default-color-statue text-center"
                      :class="{
                        'red-color': item.ihs_statue == 'facture non validée',
                        'green-color': item.ihs_statue == 'paye',
                        'blue-color': item.ihs_statue == 'passive',
                        'red-color montant-bare': item.ihs_statue == 'annulee'
                      }"
                    >
                      {{ computedFactureVideValue(item, 'ihs', 'montant') }}
                    </span>
                    <template-commercial-cactus
                      v-if="
                        !computedFactureVide(item.montant_ihs, item.ihs_statue)
                      "
                      :componentName="$options.name"
                      :data="item"
                      type="IHS"
                      :ihs_type="item.ihs_type"
                      :year="filteryear"
                      :month="filterMonth"
                      :download="
                        item.ihs_statue == 'annulee' ? false : item.ihs_download
                      "
                      :id_facture="item.ihs_id"
                      :id_avoir="item.ihs_av_id"
                      :editable="
                        !isSuperAdminGetter &&
                          computedEditableStatutIFrais({
                            item: item,
                            column: 'ihs'
                          })
                      "
                    />
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'INTERNE IHS',
                      key: 'montant_ihs'
                    })
                  "
                >
                  <EditableInput
                    v-if="
                      !computedFactureVide(item.montant_ihs, item.ihs_statue) &&
                        item.ihs_download == true
                    "
                    :editable="
                      computedEditableStatutIFrais({
                        item: item,
                        column: 'ihs'
                      })
                    "
                    champName="ihs_statue"
                    editableType="select"
                    :optionsSelect="computedValidationList"
                    :item="{
                      item: item,
                      id_to_use: item.ihs_id
                    }"
                    :value="item.ihs_statue"
                    :updateFunction="updateFraisBddCommericalCactusStatut"
                    label="value"
                    :positionSelect="true"
                    formate="regieFacture"
                    :showIconEdit="true"
                  />
                  <span v-else class="text-center">-</span>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'INTERNE IHS',
                      key: 'montant_ihs'
                    })
                  "
                >
                  {{ computedFactureVideValue(item, 'ihs', 'ihs_date') }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'INTERNE IHS',
                      key: 'montant_ihs'
                    })
                  "
                >
                  {{ computedFactureVideValue(item, 'ihs', 'ihs_numero') }}
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'INTERNE IHS',
                      key: 'montant_ihs'
                    })
                  "
                >
                  <div class="block-service-style text-center">
                    <template
                      v-if="
                        !computedFactureVide(item.montant_ihs, item.ihs_statue)
                      "
                    >
                      <EditableInput
                        v-if="
                          !computedFactureVide(
                            item.montant_ihs,
                            item.ihs_statue
                          ) && item.ihs_download == true
                        "
                        :editable="
                          computedEditableStatutInstallateurFrais({
                            item: item,
                            column: 'ihs'
                          })
                        "
                        champName="ihs_statue_installateur"
                        editableType="select"
                        :optionsSelect="computedValidationListInstallateur"
                        :item="{
                          item: item,
                          id_to_use: item.ihs_id
                        }"
                        :value="item.ihs_statue_installateur"
                        :updateFunction="
                          updateFraisBddCommericalCactusStatutInstallateur
                        "
                        label="value"
                        :positionSelect="true"
                        formate="regieFacture"
                        :showIconEdit="true"/>
                      <icon-info-component :item="item.ihs_responsable_email"
                    /></template>

                    <span v-else class="text-center">-</span>
                  </div>
                </td>
                <td
                  class=" item-details-style justify-content-sb-frais-commercial-cactus"
                  v-if="
                    computedShowDetailsColomn({
                      value: 'INTERNE IHS',
                      key: 'montant_ihs'
                    })
                  "
                >
                  {{ computedFactureVideValue(item, 'ihs', 'ihs_av_numero') }}
                </td>
              </span></template
            >
          </b-table>
        </div>
        <div v-if="computedTotal > 0" class="style-somme-th-ttc">
          <p class="m-2">
            Total ht:
            {{ computedTotal.toFixed(2).toLocaleString('en-US') }}€
            {{ '   ' }}Total ttc:

            {{ computedTotalTTC.toFixed(2).toLocaleString('en-US') }}€
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EditableInput from '../../component/EditableInput.vue';
import ExtractionCactus from './component/commercialCactusFrais/extractionCactus.vue';
import TemplateCommercialCactus from './component/commercialCactusFrais/templateCommercialCactus.vue';
export default {
  name: 'Gestion-Interne-IHS',

  data() {
    return {
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterWeek: null,
      filterFiliale: null,
      filterDepot: null,
      filterType: 'ihs',
      filterStatut: null,
      flashMessageId: null,
      sortBy: 'name',
      searchValue: null,
      checkAll: false,
      sommeSelected: 0,
      fields: [
        {
          key: 'check_all',
          label: '',
          views: true,
          tdClass: 'color',
          thClass: 'th-color',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais',
          views: true,
          for: 'all'
        },

        {
          key: 'installeur',
          label: 'Filiale Installateur',
          views: true,
          thClass: 'th-installeur-column-frais',
          tdClass: 'installeur-column-frais',
          for: 'all'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'montant_final',
          label: 'Montant min',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'ihs_type',
          label: 'Société pricipale',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'regie',
          label: 'Régie',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        /* {
          key: 'previsiteur',
          label: 'Prévisiteur',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'acompte',
          label: 'Acompte',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        }, */
        {
          key: 'agent_commercial_terrain',
          label: 'Agent commercial terrain',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'agent_commercial',
          label: 'Agent commercial',
          views: true,
          thClass: '',
          tdClass: '',
          for: 'all'
        },
        {
          key: 'montant_commercial',
          label: 'ALTEO FRANCE Support Commercial ',
          views: true,
          thClass: 'bg-color-th-marie ',
          for: 'all'
        },
        {
          key: 'commercial_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'all'
        },
        {
          key: 'commercial_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'all'
        },
        {
          key: 'commercial_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'all'
        },
        {
          key: 'commercial_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-marie ',
          for: 'all'
        },
        {
          key: 'montant_interne',
          label: 'ALTEO FRANCE Régie Commerciale INTERNE',
          views: true,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'all'
        },
        {
          key: 'interne_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'all'
        },
        {
          key: 'interne_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'all'
        },
        {
          key: 'interne_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'all'
        },
        {
          key: 'interne_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-maintenance-pac ',
          for: 'all'
        },
        {
          key: 'montant_ihs',
          label: 'INTERNE IHS',
          views: true,
          details: true,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        },
        {
          key: 'ihs_statue',
          label: 'Statut',
          views: false,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        },
        {
          key: 'ihs_date',
          label: 'Date',
          views: false,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        },
        {
          key: 'ihs_numero',
          label: 'Numéro de facture',
          views: false,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        },
        {
          key: 'ihs_statue_installateur',
          label: 'Statut de paiement installateur',
          views: false,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        },
        {
          key: 'ihs_av_numero',
          label: 'Numéro de facture avoir',
          views: false,
          thClass: 'bg-color-th-ihs ',
          for: 'all'
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'getAllFraisBddInterneIhs',
      'updateInterface',
      'updateFraisBddInterneIhs',
      'updateFraisBddCommericalCactusStatutInstallateur',
      'updateFraisBddCommericalCactusStatut',
      'downloadExtraitSupportServiceAction',
      'getDataForRowFraisIHS',
      'downloadExtraitInstalleurAction'
    ]),
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowFraisIHS({
          item: data.item,
          year: this.filteryear,
          month: this.filterMonth,
          search: this.searchValue,
          week: this.filterWeek,
          filiale: this.filterFiliale,
          depot: this.filterDepot,
          statue: this.filterStatut
        });
      }
    },
    handleDownloadExtraitInstalleur(item) {
      if (this.filterMonth != null && this.filterType == null) {
        this.$confirm(
          '',
          'Êtes-vous sur de vouloir télecharger tous les extraits pour ' +
            item +
            ' ?',
          'warning'
        )
          .then(() => {
            this.downloadExtraitInstalleurAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item,
              type: this.typeArray
            });
          })
          .catch(() => {});
      }
    },
    handleDownloadExtraitSupportService(item) {
      if (this.filterMonth != null) {
        this.downloadExtraitSupportServiceAction({
          year: this.filteryear,
          month: this.filterMonth,
          filiale: item.item.nom,
          type: item.type
        });
      }
    },
    rowClass(item, rowType) {
      if (rowType === 'row-details') {
        return 'subLigneEnCourDeValidation';
      }
      return 'ligneEnCourDeValidation';
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      //if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      //}
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      //  }
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      //if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handleChangeDepot() {
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handlechangeFilialeSupport() {
      this.filterStatut = null;
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    handleFilter() {
      this.getAllFraisBddInterneIhs({
        year: this.filteryear,
        month: this.filterMonth,
        search: this.searchValue,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        depot: this.filterDepot,
        statue: this.filterStatut
      });
      this.setLocalStorageInterneIhs();
    },
    reloadData() {
      this.checkAll = false;
      this.filterStatut = null;
      this.handleFilter();
      this.sommeSelected = 0;
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
      this.setLocalStorageInterneIhs();
    },
    setVisibilityFieldsHeader(key) {
      if (this.computedHeaderVisibility(key)) {
        this.fields.map(item => {
          if (
            item.key == key.split('_')[1] + '_statue' ||
            item.key == key.split('_')[1] + '_date' ||
            item.key == key.split('_')[1] + '_numero' ||
            item.key == key.split('_')[1] + '_av_numero' ||
            item.key == key.split('_')[1] + '_statue_installateur'
          ) {
            item.views = !item.views;
          }
          if (item.key == key) {
            item.details = !item.details;
          }
        });
      }
    },
    checkAllFunction() {
      this.getFraisBddInterneIhsData.map(item => {
        item.check = this.checkAll;
        item.projects.map(itemD => (itemD.check = this.checkAll));
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemD => (itemD.check = item.check));
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    },
    setLocalStorageInterneIhs() {
      localStorage.setItem(
        'interne-ihs',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          week: this.filterWeek,
          search: this.searchValue,
          statue: this.filterStatut,
          depot: this.filterDepot,
          filiale: this.filterFiliale
        })
      );
    }
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'computedListType',
      'getLoadingFraisBddInterneIhs',
      'isResponsableFiliale',
      'getErrorFraisBddInterneIhs',
      'getFraisBddInterneIhsData',
      'cantUpdate',
      'isSuperAdminGetter',
      'cantCreate',
      'cantExport',
      'isResponsableAlteao',
      'responsableValidation'
    ]),
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        search: this.searchValue,
        week: this.filterWeek,
        filiale: this.filterFiliale,
        depot: this.filterDepot,
        statue: this.filterStatut
      };
    },
    computedCheckedRows() {
      return this.getFraisBddInterneIhsData.filter(k => {
        return k.projects.some(e => e.check == true);
      });
    },
    computedShowDetailsColomn() {
      return function(data) {
        if (data && data.key) {
          if (
            this.fields.find(
              item => item.key == data.key && item.details == false
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      };
    },
    computedFactureVide() {
      return function(montant, statue) {
        if (
          statue == 'facture non validée' &&
          (montant == '0.00' ||
            montant == '0,00' ||
            montant == '0' ||
            montant == 0)
        ) {
          return true;
        }
        return false;
      };
    },
    computedFactureVideValue() {
      return function(item, column, type) {
        if (item && column && type) {
          let statue = column + '_statue';
          let montant = 'montant_' + column;

          if (
            item[statue] == 'facture non validée' &&
            (item[montant] == '0.00' || item[montant] == '0,00')
          ) {
            return '-';
          }
          if (type == 'montant') {
            return item[montant] + ' €';
          } else {
            return item[type];
          }
        }
      };
    },
    computedValidationList() {
      return [
        { value: 'passive', full_name: 'Facture passive' },
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    computedFields() {
      return this.fields.filter(item => item.views == true);
    },
    computedFraisIhs() {
      return this.getFraisBddInterneIhsData;
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'montant_commercial' ||
          data == 'montant_interne' ||
          data == 'montant_ihs'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedEditableStatutIFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdminGetter || this.responsableValidation) {
            return true;
          }
          if (
            this.cantUpdate(this.$options.name) &&
            this.isResponsableFiliale &&
            data.item[data.column + '_statue'] != 'paye' &&
            data.item[data.column + '_statue'] != 'annulee' &&
            data.item[data.column + '_statue'] != 'passive'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedEditableStatutInstallateurFrais: function() {
      return function(data) {
        if (data) {
          if (this.isSuperAdminGetter) {
            return true;
          }
          if (
            this.cantUpdate(this.$options.name) &&
            this.isResponsableAlteao &&
            data.item[data.column + '_statue'] != 'paye' &&
            data.item[data.column + '_statue'] != 'annulee' &&
            data.item[data.column + '_statue'] != 'passive' &&
            data.item[data.column + '_statue_installateur'] != 'paye'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedTotal() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j]['montant_ihs']
                        .replace(' ', '')
                        .replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedTotalTTC() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j]['montant_ihs']
                        .replace(' ', '')
                        .replace(',', '.')
                    ) *
                      1.2 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    }
  },

  components: {
    EditableInput,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    TemplateCommercialCactus,
    iconInfoComponent: () => import('./component/iconInfoComponent.vue'),
    validationStatueInstallateur: () =>
      import('./component/ValidationStatueInstallateur.vue'),
    ExtractionCactus,
    generationFactureAvoirFrais: () =>
      import('./component/generationFactureAvoirFrais.vue')
  },
  async mounted() {
    this.updateInterface('ihs');
    var courantYear = moment().year();
    this.filteryear = courantYear;

    if (localStorage.getItem('interne-ihs')) {
      this.filteryear = JSON.parse(localStorage.getItem('interne-ihs')).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;

    if (localStorage.getItem('interne-ihs')) {
      this.filterMonth = JSON.parse(localStorage.getItem('interne-ihs')).month;
      this.filterWeek = JSON.parse(localStorage.getItem('interne-ihs')).week;
      this.searchValue = JSON.parse(localStorage.getItem('interne-ihs')).search;
      this.filterStatut = JSON.parse(
        localStorage.getItem('interne-ihs')
      ).statue;
      this.filterDepot = JSON.parse(localStorage.getItem('interne-ihs')).depot;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('interne-ihs')
      ).filiale;
    } else {
      this.filteryear = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageInterneIhs();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.handleFilter();
  },
  filters: {
    statutFactureFormat: function(value) {
      switch (value) {
        case 'en attend':
          return 'En cours de validation';
        case 'refuse':
          return 'Réfusé';
        case 'annuler':
          return 'Annuler';
        case 'valid':
          return 'Appel à facturation validé';
        case 'encore de verification':
          return 'En cours de vérification';
        case 'facture valid':
          return 'Facture validé';
        case 'facture refuse':
          return 'Facture réfusé';
        case 'encore de paiement':
          return 'En cours de paiement';
        case 'paye':
          return 'Facture payée';
        case 'a paye':
          return 'Facture à payer';
        case 'facture non validée':
          return 'Facture non validée';
        case 'annulee':
          return 'Facture annulée';
        case 'passive':
          return 'Facture Passive';
        default:
          if (value) {
            return value.replace(/_/g, ' ');
          } else {
            return value;
          }
      }
    }
  },
  watch: {
    getErrorFraisBddInterneIhs: function() {
      if (this.getErrorFraisBddInterneIhs != null) {
        const objectFlash = {
          blockClass: 'custom-block-class',
          contentClass: 'custom-content-class',
          message: ''
        };
        if (Array.isArray(this.getErrorFraisBddInterneIhs)) {
          for (let e in this.getErrorFraisBddInterneIhs) {
            objectFlash.message =
              objectFlash.message + ' ' + this.getErrorFraisBddInterneIhs[e];
          }
        } else {
          objectFlash.message = this.getErrorFraisBddInterneIhs;
        }
        this.flashMessageId = this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          blockClass: objectFlash.blockClass,
          contentClass: objectFlash.contentClass,
          message: objectFlash.message
        });
      } else {
        this.flashMessage.deleteMessage(this.flashMessageId);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.flashMessage.deleteMessage(this.flashMessageId);
    next();
  }
};
</script>

<style scoped lang="scss">
.frais-interne-ihs {
  .button-export-style {
    padding-left: 1.5px;
    padding-right: 1.7px;
    img {
      width: 24px;
    }
  }
  .entete {
    padding: 5px 7px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableFraisInterneIhs {
      max-height: calc(100vh - 180px) !important;
      height: calc(100vh - 180px) !important;
      margin-bottom: 0px;
    }
    .icon-plus {
      margin-right: 7px;
      color: #ffff;
      font-size: 10px;
      &:hover {
        color: #8d8cb7;
      }
    }
  }
  .block-service-style {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .style-somme-th-ttc {
    font-family: 'Montserrat', sans-serif;
    background-color: #d0e4f5;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: auto;
    height: 35px;
    border-radius: 30px;
    z-index: 120;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableFraisInterneIhs {
  td,
  th {
    min-width: 100px;
    width: 100px;
  }
  td {
    line-height: 10px;
  }
  span {
    cursor: pointer;
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .icon-plus-h {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .item-details-style {
    min-width: 100px;
    width: 100px;
  }
}
.color {
  border-left: none !important;
}
.table-rapport-style .customTableFraisInterneIhs {
  td,
  th {
    font-size: 9px;
  }
  .justify-content-sb-frais-interne-ihs {
    display: block !important;
    line-height: 11px !important;
  }
}
</style>
