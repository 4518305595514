var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frais-interne-ihs"},[_c('div',{staticClass:"entete"},[_c('div',{staticClass:"box-all-filter"},[_c('div',{staticClass:"box-label-champ mr-1"},[_vm._m(0),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select b-form-select-new-style-w-1",attrs:{"options":_vm.ListYear},on:{"change":_vm.handleChangeYear},model:{value:(_vm.filteryear),callback:function ($$v) {_vm.filteryear=$$v},expression:"filteryear"}})],1),_c('div',{staticClass:"box-label-champ mr-1"},[_vm._m(1),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select w-63-px  ",attrs:{"options":[
            { value: null, text: 'Tous' } ].concat( _vm.computedGetMonthForYearTh
          ),"text-field":"text","value-field":"value"},on:{"change":_vm.handleChangeMonth},model:{value:(_vm.filterMonth),callback:function ($$v) {_vm.filterMonth=$$v},expression:"filterMonth"}})],1),_c('div',{staticClass:"box-label-champ mr-1"},[_vm._m(2),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select w-63-px ",attrs:{"options":[
            { value: null, text: 'Tous' } ].concat( _vm.getcomputedListWeek(_vm.filterMonth)
          ),"text-field":"text","value-field":"value"},on:{"change":_vm.handleChangeWeek},model:{value:(_vm.filterWeek),callback:function ($$v) {_vm.filterWeek=$$v},expression:"filterWeek"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(3),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select  w-63-px",attrs:{"options":[
            { value: null, text: 'Tous' } ].concat( _vm.getcomputedFiliale(_vm.filterMonth, _vm.filterWeek)
          ),"text-field":"text","value-field":"value"},on:{"change":_vm.handleChangeFiliale},model:{value:(_vm.filterFiliale),callback:function ($$v) {_vm.filterFiliale=$$v},expression:"filterFiliale"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(4),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select  w-63-px",attrs:{"options":[
            { value: null, text: 'Tous' } ].concat( _vm.getcomputedDepots(_vm.filterMonth, _vm.filterWeek, _vm.filterFiliale)
          ),"text-field":"text","value-field":"value"},on:{"change":_vm.handleChangeDepot},model:{value:(_vm.filterDepot),callback:function ($$v) {_vm.filterDepot=$$v},expression:"filterDepot"}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(5),_c('b-form-select',{staticClass:"b-form-select-new-style bg-select  w-78-px",attrs:{"options":[
            { full_name: 'Tous', valus: null } ].concat( _vm.computedValidationList
          ),"text-field":"full_name","value-field":"value"},on:{"change":_vm.handleFilter},model:{value:(_vm.filterStatut),callback:function ($$v) {_vm.filterStatut=$$v},expression:"filterStatut"}})],1),(_vm.getLoadingFraisBddInterneIhs)?_c('div',{staticClass:"chargement chargement-loading-icon"},[_vm._m(6)]):_vm._e(),_c('div',{staticClass:"box-end-position"},[_c('search',{attrs:{"value":_vm.searchValue},on:{"changeSearchValue":_vm.changeSearchValue}}),(_vm.cantExport(this.$options.name))?_c('extraction-cactus',{attrs:{"filterType":'IHS',"filteryear":_vm.filteryear,"filterMonth":_vm.filterMonth,"dataToUse":_vm.computedFraisIhs,"selected":_vm.computedCheckedRows.length,"filter":_vm.ComputedFilter}}):_vm._e(),_c('generation-facture-avoir-frais',{attrs:{"interfaceName":this.$options.name,"filterType":'IHS',"dataToUse":_vm.computedFraisIhs,"curentInterface":"cactus"},on:{"reloadData":_vm.reloadData}}),(
            _vm.cantUpdate(this.$options.name) &&
              _vm.filterType &&
              _vm.computedCheckedRows.length
          )?_c('validation-statue-installateur',{attrs:{"filterType":'IHS',"dataToUse":_vm.computedFraisIhs,"curentInterface":"cactus"},on:{"reloadData":_vm.reloadData}}):_vm._e()],1)]),_c('div',{staticClass:"body-box-rapport "},[_c('div',{staticClass:"table-rapport-style w-100-p"},[_c('b-table',{staticClass:"custom-table-style customTableFraisInterneIhs table-header",attrs:{"show-empty":"","id":"my-table","items":_vm.computedFraisIhs,"fields":_vm.computedFields,"bordered":"","sticky-header":"","hover":"","responsive":"","head-variant":"light","tbody-tr-class":_vm.rowClass,"sort-by":_vm.sortBy,"empty-text":"Il n'y a aucun enregistrement à afficher"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"text-uppercase"},[(data.field.details == false)?_c('font-awesome-icon',{staticClass:"icon-plus align-icon",attrs:{"icon":"minus-square"},on:{"click":function($event){return _vm.setVisibilityFieldsHeader(data.field.key)}}}):_vm._e(),(data.field.details == true)?_c('font-awesome-icon',{staticClass:"icon-plus align-icon",attrs:{"icon":"plus-square"},on:{"click":function($event){return _vm.setVisibilityFieldsHeader(data.field.key)}}}):_vm._e(),(data.field.key != 'check_all')?_c('span',{class:{
                  'white-color': _vm.computedHeaderVisibility(data.field.key)
                }},[_vm._v(" "+_vm._s(data.field.label))]):_c('div',[_c('b-form-checkbox',{staticClass:"check-th",attrs:{"value":true,"unchecked-value":false},on:{"change":_vm.checkAllFunction},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1)],1)]}},{key:"cell(check_all)",fn:function(data){return [(data.item.projects.length != 0)?_c('b-form-checkbox',{staticClass:"check-th",attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.checkAllVerif(data.item)}},model:{value:(data.item.check),callback:function ($$v) {_vm.$set(data.item, "check", $$v)},expression:"data.item.check"}}):_vm._e()]}},{key:"cell(details)",fn:function(data){return [_c('button',{staticClass:"btn btn-anomalie",on:{"click":function($event){return _vm.getDataForRow(data)}}},[(data.detailsShowing == false)?_c('font-awesome-icon',{staticClass:"icon-plus-h",attrs:{"icon":"plus-square","title":"Afficher les dossiers"}}):_vm._e(),(data.detailsShowing == true)?_c('font-awesome-icon',{staticClass:"icon-plus-h",attrs:{"icon":"minus-square","title":"Masques les dossiers"}}):_vm._e()],1)]}},{key:"cell(numero_dossier)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(installeur)",fn:function(data){return [_c('span',{attrs:{"title":'Télécharger extrait ' + data.item.name},on:{"click":function($event){return _vm.handleDownloadExtraitInstalleur(data.item.name)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(regie)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(montant_final)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(agent_commercial_terrain)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(agent_commercial)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(montant_commercial)",fn:function(data){return [_c('span',{on:{"click":function($event){return _vm.handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_commercial'
                })}}},[_vm._v(" "+_vm._s(data.item.commercial)+" ")])]}},{key:"cell(commercial_statue)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(commercial_date)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(commercial_numer)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(commercial_numero)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(commercial_statue_installateur)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(montant_interne)",fn:function(data){return [_c('span',{on:{"click":function($event){return _vm.handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_interne'
                })}}},[_vm._v(" "+_vm._s(data.item.interne)+" ")])]}},{key:"cell(interne_statue)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(interne_date)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(interne_numer)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(interne_numero)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(interne_statue_installateur)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(montant_ihs)",fn:function(data){return [_c('span',{on:{"click":function($event){return _vm.handleDownloadExtraitSupportService({
                  item: data.item,
                  type: 'commercial_ihs'
                })}}},[_vm._v(" "+_vm._s(data.item.ihs)+" ")])]}},{key:"cell(ihs_statue)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(ihs_date)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(ihs_numer)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(ihs_numero)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(ihs_statue_installateur)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"cell(ihs_type)",fn:function(){return [_vm._v(" - ")]},proxy:true},{key:"row-details",fn:function(data){return [(data.item.loadingDetailt)?_c('div',[_c('circles-to-rhombuses-spinner',{staticClass:"loading-details-frais",attrs:{"animation-duration":1500,"size":50,"color":'#ff1d5e'}})],1):_vm._l((data.item.projects),function(item,index){return _c('span',{key:data.item.nom + item.nom1 + index,staticClass:"align-td-item border-botom"},[_c('td',{staticClass:"color"},[_c('div',{staticClass:"block-service-style"},[_c('b-form-checkbox',{staticClass:"check-th-details ml-2",attrs:{"value":true,"unchecked-value":false},on:{"change":function($event){return _vm.checkAllVerifDetails(data.item, item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1)]),_c('td',{staticClass:"details-column-frais"},[_c('div',{staticClass:"block-service-style"})]),_c('td',{staticClass:"installeur-column-frais"},[_vm._v(" "+_vm._s(item.nom1 + ' ' + item.prenom1)+" ")]),_c('td',{staticClass:"item-details-style"},[_vm._v(" "+_vm._s(item.numero_dossier)+" ")]),_c('td',{staticClass:"item-details-style"},[_vm._v(" "+_vm._s(item.montant_final)+" ")]),_c('td',{staticClass:"item-details-style"},[_c('EditableInput',{attrs:{"editable":_vm.computedEditableStatutIFrais({
                      item: item,
                      column: 'ihs'
                    }),"champName":"ihs_type","editableType":"select","optionsSelect":[
                    { value: 'IHS HABITAT', full_name: 'IHS HABITAT' },
                    {
                      value: 'RENOV & MON HABITAT',
                      full_name: 'RENOV & MON HABITAT'
                    }
                  ],"item":item,"value":item.ihs_type,"updateFunction":_vm.updateFraisBddInterneIhs,"label":"value","positionSelect":true,"formate":"regieFacture"}})],1),_c('td',{staticClass:"item-details-style"},[_vm._v(" "+_vm._s(item.regie)+" ")]),_c('td',{staticClass:"item-details-style"},[_vm._v(" "+_vm._s(item.agent_commercial_terrain)+" ")]),_c('td',{staticClass:"item-details-style"},[_vm._v(" "+_vm._s(item.agent_commercial)+" ")]),(
                  _vm.computedShowDetailsColomn('ALTEO FRANCE Support Commercial')
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_c('div',{staticClass:"block-service-style"},[_c('span',{staticClass:"default-color-statue text-center",class:{
                      'red-color':
                        item.commercial_statue == 'facture non validée',
                      'green-color': item.commercial_statue == 'paye',
                      'blue-color': item.commercial_statue == 'passive',
                      'red-color montant-bare':
                        item.commercial_statue == 'annulee'
                    }},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'commercial', 'montant'))+" ")])])]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm._f("statutFactureFormat")(_vm.computedFactureVideValue( item, 'commercial', 'commercial_statue' )))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue( item, 'commercial', 'commercial_date' ))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue( item, 'commercial', 'commercial_numero' ))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Support Commercial',
                    key: 'montant_commercial'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm._f("statutFactureFormat")(_vm.computedFactureVideValue( item, 'commercial', 'commercial_statue_installateur' )))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn(
                    'ALTEO FRANCE Régie Commerciale INTERNE'
                  )
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_c('div',{staticClass:"block-service-style"},[_c('span',{staticClass:"default-color-statue text-center",class:{
                      'red-color':
                        item.interne_statue == 'facture non validée',
                      'green-color': item.interne_statue == 'paye',
                      'blue-color': item.interne_statue == 'passive',
                      'red-color montant-bare':
                        item.interne_statue == 'annulee'
                    }},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'interne', 'montant'))+" ")])])]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm._f("statutFactureFormat")(_vm.computedFactureVideValue(item, 'interne', 'interne_statue')))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'interne', 'interne_date'))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'interne', 'interne_numero'))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'ALTEO FRANCE Régie Commerciale INTERNE',
                    key: 'montant_interne'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm._f("statutFactureFormat")(_vm.computedFactureVideValue( item, 'interne', 'interne_statue_installateur' )))+" ")]):_vm._e(),(_vm.computedShowDetailsColomn('INTERNE IHS'))?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_c('div',{staticClass:"block-service-style"},[_c('span',{staticClass:"default-color-statue text-center",class:{
                      'red-color': item.ihs_statue == 'facture non validée',
                      'green-color': item.ihs_statue == 'paye',
                      'blue-color': item.ihs_statue == 'passive',
                      'red-color montant-bare': item.ihs_statue == 'annulee'
                    }},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'ihs', 'montant'))+" ")]),(
                      !_vm.computedFactureVide(item.montant_ihs, item.ihs_statue)
                    )?_c('template-commercial-cactus',{attrs:{"componentName":_vm.$options.name,"data":item,"type":"IHS","ihs_type":item.ihs_type,"year":_vm.filteryear,"month":_vm.filterMonth,"download":item.ihs_statue == 'annulee' ? false : item.ihs_download,"id_facture":item.ihs_id,"id_avoir":item.ihs_av_id,"editable":!_vm.isSuperAdminGetter &&
                        _vm.computedEditableStatutIFrais({
                          item: item,
                          column: 'ihs'
                        })}}):_vm._e()],1)]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'INTERNE IHS',
                    key: 'montant_ihs'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[(
                    !_vm.computedFactureVide(item.montant_ihs, item.ihs_statue) &&
                      item.ihs_download == true
                  )?_c('EditableInput',{attrs:{"editable":_vm.computedEditableStatutIFrais({
                      item: item,
                      column: 'ihs'
                    }),"champName":"ihs_statue","editableType":"select","optionsSelect":_vm.computedValidationList,"item":{
                    item: item,
                    id_to_use: item.ihs_id
                  },"value":item.ihs_statue,"updateFunction":_vm.updateFraisBddCommericalCactusStatut,"label":"value","positionSelect":true,"formate":"regieFacture","showIconEdit":true}}):_c('span',{staticClass:"text-center"},[_vm._v("-")])],1):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'INTERNE IHS',
                    key: 'montant_ihs'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'ihs', 'ihs_date'))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'INTERNE IHS',
                    key: 'montant_ihs'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'ihs', 'ihs_numero'))+" ")]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'INTERNE IHS',
                    key: 'montant_ihs'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_c('div',{staticClass:"block-service-style text-center"},[(
                      !_vm.computedFactureVide(item.montant_ihs, item.ihs_statue)
                    )?[(
                        !_vm.computedFactureVide(
                          item.montant_ihs,
                          item.ihs_statue
                        ) && item.ihs_download == true
                      )?_c('EditableInput',{attrs:{"editable":_vm.computedEditableStatutInstallateurFrais({
                          item: item,
                          column: 'ihs'
                        }),"champName":"ihs_statue_installateur","editableType":"select","optionsSelect":_vm.computedValidationListInstallateur,"item":{
                        item: item,
                        id_to_use: item.ihs_id
                      },"value":item.ihs_statue_installateur,"updateFunction":_vm.updateFraisBddCommericalCactusStatutInstallateur,"label":"value","positionSelect":true,"formate":"regieFacture","showIconEdit":true}}):_vm._e(),_c('icon-info-component',{attrs:{"item":item.ihs_responsable_email}})]:_c('span',{staticClass:"text-center"},[_vm._v("-")])],2)]):_vm._e(),(
                  _vm.computedShowDetailsColomn({
                    value: 'INTERNE IHS',
                    key: 'montant_ihs'
                  })
                )?_c('td',{staticClass:" item-details-style justify-content-sb-frais-commercial-cactus"},[_vm._v(" "+_vm._s(_vm.computedFactureVideValue(item, 'ihs', 'ihs_av_numero'))+" ")]):_vm._e()])})]}}])})],1),(_vm.computedTotal > 0)?_c('div',{staticClass:"style-somme-th-ttc"},[_c('p',{staticClass:"m-2"},[_vm._v(" Total ht: "+_vm._s(_vm.computedTotal.toFixed(2).toLocaleString('en-US'))+"€ "+_vm._s(' ')+"Total ttc: "+_vm._s(_vm.computedTotalTTC.toFixed(2).toLocaleString('en-US'))+"€ ")])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style label-box-style-w-1 p-4-5"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Année")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  label-box-style-w-1"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Mois")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style w-46-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Semaine")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  w-93-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Filiale Installateur")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  label-box-style-w-1"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Dépôt")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-box-style  w-46-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Statut")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }